import Link from "next/link";
import Image from "components/image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richContentOptions } from "components";

export default function Horizontal({ fields, sys, includes }) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto lg:py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-brand-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              {fields.title && (
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  {fields.title}
                </h2>
              )}
              {fields.content && (
                <div className="mt-4 text-lg leading-6 text-white space-y-4">
                  {documentToReactComponents(
                    fields.content,
                    richContentOptions
                  )}
                </div>
              )}
              {fields.ctAs && (
                <div className="flex flex-wrap">
                  {fields.ctAs.map((cta) => (
                    <Link
                      href={cta.fields.link}
                      key={cta.sys.id}
                      className="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-brand-600 hover:bg-brand-50 mr-8"
                    >
                      {cta.fields.text}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
          {fields.image && (
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <Image
                fields={fields.image}
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                alt="Cosmetic College training room facilties"
                fill
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
